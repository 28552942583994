<template>
    <div class="password">
        <van-nav-bar
            title="修改密碼"
            left-text="返回"
            left-arrow
            @click-left="goBack"
        />
        <!-- 绑定邮箱找回 -->
        <div class="content">
            <div class="tips">
                <p>您正在為帳號 {{ username }} 修改密碼，請妥善保管您的新密碼</p>
            </div>
            <van-form @submit="handleChange">
                <van-field
                    v-model="oldPassword"
                    type="password"
                    clearable
                    label="原密碼"
                    left-icon="lock"
                    placeholder="請輸入原密碼"
                    :rules="[{ required: true, message: '請輸入原密碼' }]"
                />
                <van-field
                    v-model="newPassword"
                    type="password"
                    clearable
                    label="新密碼"
                    left-icon="lock"
                    placeholder="請輸入新密碼"
                    :rules="[{ required: true, message: '請輸入新密碼' }]"
                />
                <van-field
                    v-model="confirmPassword"
                    type="password"
                    clearable
                    label="確認新密碼"
                    left-icon="lock"
                    placeholder="請重複輸入新密碼"
                    :rules="[{ required: true, message: '请重复输入新密碼' }]"
                />
                <div style="margin: 16px;">
                    <van-button round block color="#6e87ea" native-type="submit">立即修改</van-button>
                </div>
            </van-form>

        </div>
    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter} from 'vue-router'
import { useStore } from 'vuex'
import { Toast } from "vant";
import { reqestPassword } from "@/service/user"

export default {
    
    setup() {
        const store = useStore()
        const router = useRouter()

        const state = reactive({
            username: '',
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        })

        onMounted(() => {
            state.username = store.getters.username
        });

        const goBack = () => {
            router.go(-1)
        }

        const handleChange = async () => {
            if (state.newPassword.length < 6 || state.newPassword.length > 18) {
                Toast("賬號長度為6-18位字符")
                return;
            }
            if (state.newPassword != state.confirmPassword) {
                Toast("請重複輸入新密碼")
                return;
            }

            Toast.loading('發送中');
            await reqestPassword({
                oldPassword:state.oldPassword,
                password:state.newPassword,
                passwordConfirmation:state.newPassword
            });
            store.commit("setPassword", state.newPassword)
            store.commit("setLastPassword", state.newPassword)
            Toast("修改成功")
            state.oldPassword = ''
            state.newPassword = ''
            state.confirmPassword = ''
        }

        return {
            ...toRefs(state),
            goBack,
            handleChange
        }
    },
    components: {
    }
}
</script>

<style lang="less" scoped>
.password {
    height: calc(var(--vh, 1vh) * 100);
    .content {
        margin-top: 10px;
    }
}
.tips {
    margin: 10px;
    text-align: left;
    color: #8c8c8c;
    font-size: 14px;
}
</style>